import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
// import type { PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { getToken, setToken } from "../../utils/storage";
import { encrypt } from "../../utils/hash/password";
import { instanceAdmin } from "../../service/instance";
import Swal from "sweetalert2";

export interface ILogin {
  email: string;
  password: string;
  showPass: boolean;
}

export interface ISubmitLogin {
  rejectMessage: string;
  isLoading: boolean;
  name: string;
  roleTitle: string;
  formType: any;
  formGroup: any;
  srvMng: any;
  accesToken: string;
  keyPage: string;
  appVersion: string;
  errorRoleType: string;
}

const initialState: ISubmitLogin = {
  rejectMessage: "",
  isLoading: false,
  name: "",
  roleTitle: "",
  formType: {},
  formGroup: {},
  srvMng: {},
  accesToken: getToken(),
  keyPage: "",
  appVersion: "",
  errorRoleType: ""
};

export const submitLogin = createAsyncThunk<any, any, { rejectValue: any }>(
  "user/submitLogin",
  async (dataToPost, { rejectWithValue, dispatch }) => {
    const { Email, Password } = dataToPost;
    try {
      let pasEnc = encrypt(Password);
      const resp = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_API_ADMIN}/user/login`,
        data: {
          Email,
          password: pasEnc,
        },
      });
      console.log(Email, pasEnc, "here isinya");
      const token = resp.data.data.access;
      setToken(token);
      dispatch(getRoleType(''));
      return resp.data;
    } catch (error: any) {
      const errorMessage =  error?.response.data.msg.toLowerCase().includes('email')
      if(errorMessage){
        Swal.fire('Error Login', 'Invalid login email/password', 'error')
      }else {
        Swal.fire('Error Login', error?.response.data.msg, 'error')
      }
      throw rejectWithValue(error);
    }
  }
);

export const getRoleType = createAsyncThunk<any, any, { rejectValue: any }>(
  "auth/get-role",
  async (_, { rejectWithValue }) => {
    try {
      const resp: any = await instanceAdmin.get(
        `/user/auth-data/sys_config`
        // , {
        //   headers: { Authorization: `Bearer ${data.token}` },
        // }
      );
      // console.log("resp", resp.data.data);
      const respDataStr = resp.data.data;
      const formType = respDataStr.admin_page_access.find(
        (obj: any) => obj.pge_master_access_url === "/form-type"
      );
      const formGroup = respDataStr.admin_page_access.find(
        (obj: any) => obj.pge_master_access_url === "/form-group"
      );
      const srvMng = respDataStr.admin_page_access.find(
        (obj: any) => obj.pge_master_access_url === "/service-management"
      );

      return { respDataStr, formType, formGroup, srvMng };
    } catch (err) {
      Swal.fire('Error Login', 'Role has not been assign for this module', 'error')
      throw rejectWithValue(err);
    }
  }
);

export const getAppVersion = createAsyncThunk<any, any, { rejectValue: any }>(
  "app-version",
  async ({}, { rejectWithValue }) => {
    try {
      const resp: any = await instanceAdmin.get(`/admin-app-version/latest`);
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeRejectMassage: (state) => {
      state.rejectMessage = "";
    },
    removeToken: (state) => {
      state.accesToken = "";
    },
    setPageKey: (state, action) => {
      state.keyPage = action.payload
    },
    changeLoginLoading: (state) => {
      state.isLoading = false
    },
    deleteErrMsg: (state) => {
      state.errorRoleType = ""
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.name = action.payload.name;
        state.accesToken = action.payload.data.access;
        state.roleTitle = action.payload.role_title;
      })
      .addCase(submitLogin.rejected, (state, action) => {
        state.rejectMessage = action.payload.response.data.err_details;
        state.isLoading = false;
      })
      .addCase(getRoleType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoleType.fulfilled, (state, action) => {
        const { formType, formGroup, srvMng } = action.payload
        state.isLoading = false;
        state.formType = formType;
        state.formGroup = formGroup;
        state.srvMng = srvMng
      })
      .addCase(getRoleType.rejected, (state, action) => {
        state.isLoading = false
        state.errorRoleType = action.payload.response.data.msg
      })
      .addCase(getAppVersion.pending, (state) => {
      })
      .addCase(getAppVersion.fulfilled, (state, action) => {
        state.appVersion = action.payload.data.uc_admin_app_version_number;
      })
      .addCase(getAppVersion.rejected, (state) => {
      })
  },
});

export const { removeRejectMassage, removeToken, setPageKey, changeLoginLoading, deleteErrMsg } = userSlice.actions;

export default userSlice.reducer;
