import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams,useLocation } from 'react-router-dom';

import Navbar from '../../../components/organism/navbar/Navbar';
import Sidebar from '../../../components/organism/sidebar/Sidebar';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { Breadcrumbs, Link } from '@mui/material';
import { clearStorage, getToken } from '../../../utils/storage';
import { changeLoginLoading, deleteErrMsg, getRoleType, removeToken } from '../../../store/feature/user';

const MainContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [paddingRight, setPaddingRight] = useState(0);
  const { accesToken , errorRoleType} = useAppSelector((state: RootState) => state.user);
  const { pathname } = useLocation();
  const param = useParams();
  let marginx = 0;

  const token = getToken();
  useEffect(() => {
    if (token !== '') {
      dispatch(getRoleType(''));
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (errorRoleType.length) {
      dispatch(changeLoginLoading())
      dispatch(removeToken());
      dispatch(deleteErrMsg())
      navigate("/login", { replace: true });
      clearStorage();
    }
  }, [errorRoleType]);


  useEffect(() => {
    if (open) {
      setPaddingRight(15);
    } else {
      setPaddingRight(0);
    }
  }, [open]);

  const isNotLogin = {
    paddingY: '16px',
    paddingLeft: "20px",
    paddingRight: `${paddingRight}px`,
    paddingTop: '40px',
    width:"95%"
  }
  const isLogin = {
    padding: '0',
    width:"100%"
  }

  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <CssBaseline />
      {token !== '' && (
        <>
          <Navbar open={open} setOpen={setOpen} />
          <Sidebar open={open} setOpen={setOpen} />
        </>
      )}
      <Box
        boxSizing="border-box"
        sx={pathname === '/login' ? isLogin : isNotLogin}
        >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainContainer;
